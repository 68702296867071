import React, { useCallback, useEffect, useRef, useState } from "react";
import { DecorativeCircle } from "../DecorativeCircle";

import { ReactComponent as PlaySvg } from "../../images/svg/play.svg";
import { ReactComponent as PauseSvg } from "../../images/svg/pause.svg";

import * as styles from "./AudioCircle.module.scss";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import { Markdown } from "../Markdown";
import { sendEvent } from "../../utils/analytics";

interface AudioCircleProps {
  className?: string;
  audio: string;
  children?: string;
}

const AudioCircle: React.FC<AudioCircleProps> = ({
  className,
  audio,
  children = "Listen to a  \nresearcher",
}) => {
  const { ref, inView } = useInView({
    threshold: 0.3,
  });
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const recorded = useRef<boolean>(false);

  const togglePlaying = useCallback(() => {
    if (!playing) {
      audioRef.current.play();

      if (!recorded.current) {
        recorded.current = true;
        sendEvent("engagement", {
          type: "audio play",
        });
      }
    }
    setPlaying(!playing);
  }, [playing]);

  useEffect(() => {
    if (playing) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [playing]);

  useEffect(() => {
    if (!inView) {
      setPlaying(false);
    }
  }, [inView]);

  const onTimeUpdate = useCallback((e) => {
    setProgress(e.target.currentTime / e.target.duration);
  }, []);

  return (
    <div ref={ref} className={classNames(styles.wrapper, className)}>
      <DecorativeCircle
        animate={false}
        className={styles.circle}
        pattern="006"
      />
      <audio onTimeUpdate={onTimeUpdate} ref={audioRef} src={audio} />
      <div className={styles.content} onClick={togglePlaying}>
        <Markdown className={styles.text}>{children}</Markdown>

        <span
          className={styles.progress}
          style={{ "--progress": progress } as React.CSSProperties}
        >
          <span className={styles.progressBar}>
            <span className={styles.progressBarDot} />
          </span>
        </span>

        <span className={styles.icon}>
          {playing ? <PauseSvg /> : <PlaySvg />}
        </span>
      </div>
    </div>
  );
};

export { AudioCircle };
