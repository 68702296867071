import React from "react";
import { motion, Variants } from "framer-motion";

import Pattern001 from "../../images/patterns/001.png";
import Pattern002 from "../../images/patterns/002.png";
import Pattern003 from "../../images/patterns/003.png";
import Pattern004 from "../../images/patterns/004.png";
import Pattern005 from "../../images/patterns/005.png";
import Pattern006 from "../../images/patterns/006.png";

import { circle, circleWrapper } from "./DecorativeCircle.module.scss";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";

const Patterns = {
  "001": Pattern001,
  "002": Pattern002,
  "003": Pattern003,
  "004": Pattern004,
  "005": Pattern005,
  "006": Pattern006,
};

interface DecorativeCircleProps {
  pattern: keyof typeof Patterns;
  className?: string;
  animate?: boolean;
}

const CircleVariants: Variants = {
  hide: {
    scale: 0,
  },
  show: {
    scale: 1,
  },
};

const DecorativeCircle: React.FC<DecorativeCircleProps> = ({
  pattern,
  className,
  animate = true,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <div className={classNames(circleWrapper, className)} ref={ref}>
      <motion.div
        variants={CircleVariants}
        initial={animate ? "hide" : "show"}
        animate={inView || !animate ? "show" : "hide"}
        className={circle}
        style={
          {
            "--circle-pattern": `url(${Patterns[pattern]})`,
          } as React.CSSProperties
        }
      />
    </div>
  );
};

export { DecorativeCircle };
